import "./Testimonials.css"
import React,{useState} from "react";
import { Navigation, Autoplay, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import TestimonialCard from "./TestimonialCard"

const testimonialData = [
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"Sarah Thompson",
    clientPostion: "CEO Finance Theme Group",
    clientReview:"I was amazed at how ETB-Home Staging transformed my home! From start to finish, their team was professional, creative, and dedicated. The house sold faster than I expected and at a higher price. Highly recommend their services!"
  },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"James Anderson",
    clientPostion: "CEO Finance Theme Group",
    clientReview:"As a realtor, working with ETB-Home Staging has been a game changer for my listings. Their attention to detail and eye for design make every property look its best. My clients are always impressed, and the staged homes sell quickly"
  },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"Emily Roberts",
    clientPostion: "CEO Finance Theme Group",
    clientReview:"ETB-Home Staging did a fantastic job staging our new construction homes. Their designs are modern, stylish, and perfectly suited to our target buyers. We've seen a significant increase in sales since partnering with them. Thank you!"
   },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"Michael Lee",
    clientPostion: "CEO Finance Theme Group",
    clientReview:"After using ETB-Home Staging to stage our home, we received multiple offers within days. Their team's expertise and creativity truly made our home stand out. We couldn't be happier with the results and the quick sale."
  },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"Jessica Martinez",
    clientPostion: "CEO Finance Theme Group",
    clientReview:"I can't say enough good things about ETB-Home Staging! Their personalized service and commitment to excellence are unmatched. They made our home look stunning, and the whole process was smooth and stress-free. Highly recommend to anyone looking to sell their home."
   },
  // {
  //   clientImg:"/images/icons/testimonial-male.svg",
  //   clientName:"Sara Ahmed",
  //   clientPostion: "CEO Finance Theme Group",
  //   clientReview:"Creative Fusion's app development team brought our idea to life with precision and creativity. The app runs smoothly on all devices, and our users love it. Highly recommend their services!"
  // },
  // {
  //   clientImg:"/images/icons/testimonial-male.svg",
  //   clientName:"Bilal Hussain",
  //   clientPostion: "CEO Finance Theme Group",
  //   clientReview:"Our partnership with Creative Fusion has been fantastic. Their performance marketing strategies have significantly boosted our online presence, leading to higher engagement and sales"
  // },
  // {
  //   clientImg:"/images/icons/testimonial-male.svg",
  //   clientName:"Zara Ali",
  //   clientPostion: "CEO Finance Theme Group",
  //   clientReview:"The team at Creative Fusion nailed our branding project. They captured our vision perfectly, creating a brand identity that truly represents our values and resonates with our audience."
  // },
  // {
  //   clientImg:"/images/icons/testimonial-male.svg",
  //   clientName:"Fatima Shah",
  //   clientPostion: "CEO Finance Theme Group",
  //   clientReview:"From start to finish, Creative Fusion was professional, creative, and detail-oriented. Their content creation and optimization services have greatly enhanced our online visibility."
  // }
] 

function Testimonials(props) {



  return (
    <div className="Testimonials-container py-5">
      <div className="contain w-100 container-fluid py-4">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <h3 className="head-h1 mb-2">{props.heading}
          <span className="head-bg">{props.shadeHeading}</span>
        </h3>
        <p className="body-paragraph my-4" >Read what our satisfied clients have to say about their exceptional experiences with ETB-Home Staging.</p>
        

        <div className="card-list" style={{position:"relative"}}>

        <Swiper
        spaceBetween={0}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false
        }}
        modules={[Navigation, Autoplay, Parallax]}
        observer={true}
        observeParents={true}
        parallax={true}
        breakpoints={{
          280: {
            slidesPerView: 1,
          },
          1450: {
            slidesPerView: 3,
          }
        }}
        className="testimonials-list py-5 mt-2"
        id="testimonial-cards-slider"
      >
        {testimonialData.map((clientData, ind) => (
          <SwiperSlide key={ind} className="swiper-slider-inner px-3 px-sm-0">
            <TestimonialCard
              clientImg={clientData.clientImg}
              clientName={clientData.clientName}
              clientReview={clientData.clientReview}
              clientPostion={clientData.clientPostion}
            />
          </SwiperSlide>
        ))}
      </Swiper>
        
        </div>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials